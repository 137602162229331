import { Order, OrderItem, SdkOrder } from '@/components/pages/claim/orders/types';
import { ClaimItem } from '@/components/pages/claim/types';
import { HandlebarsValue } from '@/types/insuranceDeficiency';
import { reportToSentry } from './reportToSentry';
import { MAX_PEDIATRICS_DAYS } from './constants';
import { AgeGroup, OrderStatus } from './enums';
import { InsuranceData } from '@/components/hooks/useInsuranceDeficiencyData';
import { User, InsuranceDeficiencyResponse, OrderItems, UserNoibuCustomAttrs } from '@/types';

export const capitalize = (str: string) => {
  if (!str) return str;

  return str
    ?.split(' ')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase())
    ?.join(' ');
};

export const getHourDifference = (date1: Date, date2: Date) => {
  const timeDiff = Math.abs(date1.getTime() - date2.getTime());
  return Math.floor(timeDiff / (1000 * 3600));
};

export const getLatestCanShipDate = (items: ClaimItem[]) => {
  return items?.length > 0
    ? items
        ?.filter((item: ClaimItem) => {
          return new Date(item.eligible) <= new Date();
        })
        ?.sort((a, b) => {
          return b?.canShipDate?.localeCompare(a?.canShipDate);
        })?.[0]?.canShipDate
    : '';
};

export const getLatestShipDate = (orders: Order) => {
  return orders?.items?.length > 0
    ? orders?.items
        ?.filter((item: OrderItem) => {
          return new Date(item.shipDate) <= new Date();
        })
        ?.sort((a, b) => {
          return b?.shipDate?.localeCompare(a?.shipDate);
        })?.[0]?.shipDate
    : '';
};

export const formattedDate = function prettyFormattedDate(date: string) {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

/*
  Will check if a date is real, i.e. 2-31-2022 will evaluate to false because there are not 31 days in February.
  isDateReal(2019, 12, 0); //=> false
  isDateReal(2020, 2, 29); //=> true
  isDateReal(2021, 2, 29); //=> false
  isDateReal(2022, 2, 31); //=> false
*/
export const isDateReal = (month: string, day: string, year: string) => {
  const d = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

  return parseInt(month) == d.getMonth() + 1;
};

export const serializeOrder = (order: SdkOrder) => {
  const { items } = order;
  const _items = items?.map((item) => {
    return {
      orderDate: item?.dos,
      orderQty: item.order_qty?.toString(),
      shipDate: item.ship_date,
      itemName: item.item_name,
      status: item.status,
      url: item.url,
      trackingNumber: item.tracking_number,
    };
  });
  return {
    street: order.street?.split(' ').map(capitalize).join(' '),
    zip: order.zip,
    state: order.state,
    city: capitalize(order.city),
    orderNumber: order.order_number,
    orderDate: formattedDate(order.order_date),
    firstName: capitalize(order.first_name),
    lastName: capitalize(order.last_name),
    items: _items,
    maxShippedDate: order.max_last_ship_date ? formattedDate(order.max_last_ship_date) : undefined,
  };
};

export function isValidUrl(url: string) {
  try {
    new URL(url);
  } catch (error) {
    return false;
  }

  return true;
}

export const getTranslatedStatus = (status: string, dos: string, theme = 'urology') => {
  switch (status) {
    case OrderStatus.AwaitingProcessing:
      return {
        status: `Order received and under review`,
        message: `Order must be corrected before shipment`,
      };
    case OrderStatus.AwaitingFulfillment:
      return {
        status: theme === 'urology' ? `Your order is in process and will ship on ${dos}` : `Your order is in process`,
        message:
          theme === 'urology'
            ? `Tracking will be available after your order ships on ${dos}`
            : `Tracking will be available after your order ships`,
      };
    case OrderStatus.Shipped:
      return {
        status: `Order shipped and on the way to you`,
        message: ``,
      };
    case OrderStatus.Delivered:
      return {
        status: `Order has been delivered`,
        message: ``,
      };
    case OrderStatus.Cancelled:
      return {
        status: `Order cancelled`,
        message: ``,
      };
    case OrderStatus.DuplicateOrder:
      return {
        status: `Duplicate order`,
        message: ``,
      };
    default:
      reportToSentry(`useTranslateStatus Status ${status} not found or not matched.`);

      return {
        status: status,
        message: ``,
      };
  }
};

let uniqueIdPrefix = 0;

export const getUniqueIdPrefix = () => {
  return uniqueIdPrefix++;
};

export const serializeOrderStatus = (mostRecentOrderStatus) => {
  return Object.assign(
    {},
    {
      order_number: mostRecentOrderStatus?.ordernum,
      order_date: mostRecentOrderStatus?.orderdate,
      state: mostRecentOrderStatus?.state,
      city: mostRecentOrderStatus?.city,
      zip: mostRecentOrderStatus?.zip,
      street: mostRecentOrderStatus?.street,
      street2: mostRecentOrderStatus?.street2,
      first_name: mostRecentOrderStatus?.first_name,
      last_name: mostRecentOrderStatus?.last_name,
      items: mostRecentOrderStatus?.items?.map((item) => {
        return {
          item_name: item.itemname,
          status: item.status,
          tracking_number: item.trackingnumber,
          url: item.url,
          ship_date: item.last_shipdate,
          dos: item.dos,
        };
      }),
    }
  );
};

export const isValidDateString = (date: string) => {
  const validDateString = new Date(date).toString();
  if (validDateString === 'Invalid Date') {
    return false;
  }

  return true;
};

// Returns 'pediatrics' if the patient is under 18 years old, 'adult' otherwise
export const isPediatric = (dob: string) => {
  if (!isValidDateString(dob)) {
    return null;
  }

  const todaysDate = new Date();
  const dobDate = new Date(dob);

  const millisecondsPerDay = 1000 * 60 * 60 * 24;

  // Calculate the difference in milliseconds
  const diffInMilliseconds = todaysDate.getTime() - dobDate.getTime();

  // Convert milliseconds to days
  const days = Math.floor(diffInMilliseconds / millisecondsPerDay);

  return days > MAX_PEDIATRICS_DAYS ? AgeGroup.Adult : AgeGroup.Pediatrics;
};

// This function will replace all handlebars in a string with the corresponding values passed in as arguments in the order they are passed in.
export function replaceHandlebars(text: string, ...args: HandlebarsValue[]): string | null {
  // TODO:
  // - potentially add some logging here for when we descretly return null

  // User did not pass any text
  // Builder did not return any text
  if (!text) {
    return null;
  }

  // User wants to pass plain text
  // White list of valid characters for a plain text string
  const validPlainText = text.match(/^[a-zA-Z0-9\s-.]+$/);
  if (args.length === 0 && validPlainText) {
    return text;
  }

  // User is required to pass text with handlebars
  if (args.some((arg) => arg === null || arg === undefined)) {
    return null;
  }

  // Get all matches of {{ variable_name }} from the text
  const matches = text.match(/\{\{\s*([^}]+)\s*\}\}/g) || [];

  // If no matches or no args passed to then return null so we can use our fallback text
  if (matches.length === 0 || args.length === 0) {
    return null;
  }

  // Create a variables array from the matches
  const variables = matches.map((match) => match.replace(/\{\{\s*|\s*\}\}/g, '').trim());

  // Create replacement map
  const replacementMap = new Map<string, HandlebarsValue>();

  // Map args to variables based on their order
  variables.forEach((variable, index) => {
    if (index < args.length) {
      replacementMap.set(variable, args[index]);
    }
  });

  // Replace all handlebars with their corresponding values
  let result = text;
  for (const [key, value] of replacementMap) {
    if (value === null || value === undefined) {
      return null;
    }
    const regex = new RegExp(`\\{\\{\\s*${key}\\s*\\}\\}`, 'g');
    result = result.replace(regex, String(value));
  }

  // Check if there are any unreplaced handlebars left
  if (result.match(/\{\{\s*([^}]+)\s*\}\}/g)) {
    return null;
  }

  return result;
}

// This function will run the replaceHandlebars function and if the result is null it will return the fallback text
export const getTextWithFallback = (text: string | undefined, fallback: string, ...replaceArgs: string[]) => {
  const replaced = text ? replaceHandlebars(text, ...replaceArgs) : null;
  return replaced ?? fallback;
};
// This function is for shortening a URL to its base URL
export const shortenUrl = (url: string): string => {
  try {
    const { protocol, hostname } = new URL(url);
    return `${protocol}//${hostname}`;
  } catch (error) {
    return url;
  }
};

// this function will filter out duplicate order deficiencies and return a unique list of deficiencies even if both duplicates have a null message_text
export const filterOrderDeficiencies = (deficiencies: InsuranceDeficiencyResponse[]) => {
  const uniqueDeficiencies = new Map<string, InsuranceDeficiencyResponse>();

  deficiencies.forEach((deficiency) => {
    const key = deficiency.message_name;
    if (
      !uniqueDeficiencies.has(key) ||
      (uniqueDeficiencies.get(key)?.message_text === null && deficiency.message_text)
    ) {
      uniqueDeficiencies.set(key, deficiency);
    }
  });

  return Array.from(uniqueDeficiencies.values());
};

export const parseMessagePhoneNumbers = (message: string) => {
  const phoneNumbers = message?.match(/(\+(\d{1,2})[- ]?)?(\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4,5}/g);

  if (phoneNumbers) {
    phoneNumbers.forEach((phoneNumber) => {
      message = message.replace(
        phoneNumber,
        `<a href='tel:${phoneNumber.replaceAll(
          '[()\\s-]+',
          ''
        )}' class='underline decoration-forest'><span>${phoneNumber}</span></a>`
      );
    });
  }

  return message;
};

/**
 *
 * @param text cms content that could contain place holders that need to be replaced with insurance data
 * @param values insurance values for patient to replace places holders with
 * @returns the original text with place holders swapped out with insurance data from patient
 *
 * @example
 * const text = "The minimum age is {{min_age}} and the program is called {{medicaid_name}}.";
 * const values = { minAge: 18, medicaidName: "HealthPlus" };
 * const result = replacePlaceholders(text, values);
 * console.log(result); // "The minimum age is 18 and the program is called HealthPlus."
 */
export function replacePlaceholders(text: string, values: InsuranceData): string {
  return text.replace(/\{\{(.*?)\}\}/g, (match, key) => {
    const pascalKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

    const value = values[pascalKey];
    return value ? value.toString() : match;
  });
}
export const filterUserValidAttrs = (attrs: Record<string, any>) => {
  return Object.entries(attrs).reduce((acc, [key, value]) => {
    if (value != null) {
      acc[key] = value;
    }
    return acc;
  }, {} as UserNoibuCustomAttrs);
};
/*
 * Parses an User object into the format that the PatientDataBlock component needs.
 * Creates a simplified address format with name, street address, city/state/zip, and country.
 *
 * @param user - The User object to parse
 * @returns The content array for PatientDataBlock
 */
export const parseUserForPatientDataBlock = (user: User): Array<{ title?: string; entry?: Array<string | number> }> => {
  if (!user) {
    return [];
  }

  const fullName = user.firstName && user.lastName ? `${user.firstName} ${user.lastName}`.toLowerCase() : undefined;

  const streetAddress =
    user.street && user.street2
      ? `${user.street} ${user.street2}`.toLowerCase()
      : user.street
      ? user.street.toLowerCase()
      : undefined;

  const cityStateZip =
    user.city && user.state && user.zipCode ? `${user.city}, ${user.state} ${user.zipCode}`.toLowerCase() : undefined;

  return [{ entry: [fullName, streetAddress, cityStateZip, 'united states'].filter(Boolean) as string[] }];
};

/**
 * Groups an array of OrderItems by their tracking number.
 * @param items - An array of OrderItems to be grouped.
 * @returns An array of arrays, where each sub-array contains OrderItems with the same tracking number.
 */
export const groupOrderByTrackingNumber = (items: OrderItems[]): Array<OrderItems[]> => {
  const grouped = {};
  items.forEach((item) => {
    const trackingNumber = item.tracking_number;
    if (!grouped[trackingNumber]) {
      grouped[trackingNumber] = [];
    }
    grouped[trackingNumber].push(item);
  });
  return Object.values(grouped);
};

/**
 * Sanitize String to be a css safe string
 * @param title
 * @returns String
 */
export const sanitizeString = (title = '') =>
  title
    .replace(/[()]/g, '') // Remove parentheses
    .replace(/[^a-zA-Z0-9]+/g, '-') // Replace non-alphanumeric characters with '-'
    .replace(/^-+|-+$/g, '') // Trim '-' from start and end
    .toLowerCase();

/**
 *
 * @param String ex:"2025-03-13T00:00:00"
 * @returns String ex:"03/13/2025"
 */
export const sanitizeTime = (time) => new Date(time).toLocaleDateString('en-US');

export const sanitizeNameCase = (name) => name.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

/**
 * DEtermine if a URL path is within the account section
 *
 * @param url - The URL string to check (can be a full URL or just a path)
 * @returns true if the URL path starts with '/account', false otherwise
 */
export const isAccountPath = (url: string): boolean => {
  try {
    return new URL(url).pathname.startsWith('/account');
  } catch {
    return url.includes('/account');
  }
};
