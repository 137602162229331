export enum AgeGroup {
  Adult = "adult",
  Pediatrics = "pediatrics",
}

export enum OrderStatus {
  AwaitingProcessing = "Awaiting Processing",
  AwaitingFulfillment = "Awaiting Fulfillment",
  Shipped = "Shipped",
  Delivered = "Delivered",
  Cancelled = "Cancelled",
  DuplicateOrder = "Duplicate Order",
}