export const THREE_MONTH_PROMO_FLAG = true;

export const OPT_OUT_REASONS = [
  {
    value: `I have plenty of supplies left`,
    label: `I have plenty of supplies left`,
  },
  {
    value: `I’m not changing them as often as I was`,
    label: `I’m not changing them as often as I was`,
  },
  {
    value: `I’m not happy with the product I received`,
    label: `I’m not happy with the product I received`,
  },
  {
    value: `I am not at home or am in the hospital`,
    label: `I am not at home or am in the hospital`,
  },
  {
    value: `My condition has improved`,
    label: `My condition has improved`,
  },
];

export const usaStates = [
  // USA
  {
    value: `AL`,
    text: `Alabama`,
  },
  {
    value: `AK`,
    text: `Alaska`,
  },
  {
    value: `AZ`,
    text: `Arizona`,
  },
  {
    value: `AR`,
    text: `Arkansas`,
  },
  {
    value: `CA`,
    text: `California`,
  },
  {
    value: `CO`,
    text: `Colorado`,
  },
  {
    value: `CT`,
    text: `Connecticut`,
  },
  {
    value: `DE`,
    text: `Delaware`,
  },
  {
    value: `DC`,
    text: `District of Columbia`,
  },
  {
    value: `FL`,
    text: `Florida`,
  },
  {
    value: `GA`,
    text: `Georgia`,
  },
  {
    value: `HI`,
    text: `Hawaii`,
  },
  {
    value: `ID`,
    text: `Idaho`,
  },
  {
    value: `IL`,
    text: `Illinois`,
  },
  {
    value: `IN`,
    text: `Indiana`,
  },
  {
    value: `IA`,
    text: `Iowa`,
  },
  {
    value: `KS`,
    text: `Kansas`,
  },
  {
    value: `KY`,
    text: `Kentucky`,
  },
  {
    value: `LA`,
    text: `Louisiana`,
  },
  {
    value: `ME`,
    text: `Maine`,
  },
  {
    value: `MD`,
    text: `Maryland`,
  },
  {
    value: `MA`,
    text: `Massachusetts`,
  },
  {
    value: `MI`,
    text: `Michigan`,
  },
  {
    value: `MN`,
    text: `Minnesota`,
  },
  {
    value: `MS`,
    text: `Mississippi`,
  },
  {
    value: `MO`,
    text: `Missouri`,
  },
  {
    value: `MT`,
    text: `Montana`,
  },
  {
    value: `NE`,
    text: `Nebraska`,
  },
  {
    value: `NV`,
    text: `Nevada`,
  },
  {
    value: `NH`,
    text: `New Hampshire`,
  },
  {
    value: `NJ`,
    text: `New Jersey`,
  },
  {
    value: `NM`,
    text: `New Mexico`,
  },
  {
    value: `NY`,
    text: `New York`,
  },
  {
    value: `NC`,
    text: `North Carolina`,
  },
  {
    value: `ND`,
    text: `North Dakota`,
  },
  {
    value: `OH`,
    text: `Ohio`,
  },
  {
    value: `OK`,
    text: `Oklahoma`,
  },
  {
    value: `OR`,
    text: `Oregon`,
  },
  {
    value: `PA`,
    text: `Pennsylvania`,
  },
  {
    value: `RI`,
    text: `Rhode Island`,
  },
  {
    value: `SC`,
    text: `South Carolina`,
  },
  {
    value: `SD`,
    text: `South Dakota`,
  },
  {
    value: `TN`,
    text: `Tennessee`,
  },
  {
    value: `TX`,
    text: `Texas`,
  },
  {
    value: `UT`,
    text: `Utah`,
  },
  {
    value: `VT`,
    text: `Vermont`,
  },
  {
    value: `VA`,
    text: `Virginia`,
  },
  {
    value: `WA`,
    text: `Washington`,
  },
  {
    value: `WV`,
    text: `West Virginia`,
  },
  {
    value: `WI`,
    text: `Wisconsin`,
  },
  {
    value: `WY`,
    text: `Wyoming`,
  },
] as Array<{ value: string; text: string }>;

export const MAX_PEDIATRICS_DAYS = 6570; // 18 years

const FALLBACK_INSURANCE_MODAL = {
  loadingTitle: 'Please Wait ...',
  loadingMessage: 'We are saving the insurance information you provided.',
  loadingSubMessage: 'This screen will automatically closed when saving is complete.',
  openModalBtnText: 'Add My Primary Insurance',
  headerText: 'Add Insurance Details',
  stateLabelText: 'State',
  insuranceProviderLabelText: 'Insurance Provider',
  memberIdLabelText: 'Member ID',
  saveBtnText: 'Save',
} as const;

const FALLBACK_INSURANCE_MODAL_EDIT = {
  ...FALLBACK_INSURANCE_MODAL,
  headerText: 'Edit Insurance Details',
  openModalBtnText: 'Edit Insurance Details',
} as const;

export const FALLBACK_DEFICIENCIES = {
  UNSERVICEABLE_PAYER: {
    alertBanner: 'Your Insurance Has Changed - We Can No Longer Provide Your Supplies',
    problem:
      'Due to recent changes in your insurance coverage, we regret that we are no longer able to provide your medical supplies, as we are not contracted with your current insurance provider.',
    header: 'Call State Medicaid To Find Another Provider',
    button: 'Call State Medicaid',
    urlVisitText: 'Or visit',
  },
  OUT_OF_NETWORK: {
    alertBanner: 'Your Insurance is Out of Network',
    problem:
      'We are unable to service patients with out-of-network insurance plans at this time. Please contact your insurance provider to explore in-network options for your medical supplies.',
    header: 'Call Your Insurance Now',
    ctaMessageText: 'Call your insurance to discuss changing back to your previous plan.',
    button: 'Call My Insurance',
    modal: {
      openModalText: 'Proceed With Order',
      closeModalText: 'Back',
      header: 'Are you Sure?',
      message: 'We will not be able to ship the supplies ordered today while your plan is out of network.',
      ctaButtonText: 'Proceed With Order',
    },
    urlVisitText: 'Or visit',
  },
  SOLE_PROVIDER: {
    alertBanner: 'Your Insurance Has Changed',
    problem:
      'Due to recent changes in your insurance coverage, we regret that we are no longer able to provide your medical supplies. Unfortunately, we are not contracted with your current insurance provider.',
    header: 'Call Your Insurance Now',
    ctaMessageText: 'Call your insurance to discuss changing back to your previous plan.',
    button: 'Call My Insurance',
    modal: {
      openModalText: 'Proceed With Order',
      closeModalText: 'Back',
      header: 'Are you Sure?',
      message: 'We will not be able to ship the supplies ordered today while your plan is out of network.',
      ctaButtonText: 'Proceed With Order',
    },
    urlVisitText: 'Or visit',
  },
  RESTRICTED_COVERAGE: {
    alertBanner: 'Your Insurance Has Changed',
    problem:
      'Your Medicaid coverage has recently changed to a restricted plan type. Due to these coverage limitations, we regret that we cannot provide your supplies free of charge while these restrictions remain in place.',
    header: 'Call Medicaid Now',
    ctaMessageText: 'Call your Medicaid to see if you can receive full coverage.',
    button: 'Call Medicaid',
    modal: {
      openModalText: 'Proceed With Order',
      closeModalText: 'Back',
      header: 'Are you Sure?',
      message: 'We will not be able to ship the supplies ordered today while your plan is out of network.',
      ctaButtonText: 'Proceed With Order',
    },
    urlVisitText: 'Or visit',
  },
  NEED_NEW_PRIMARY_INSURANCE: {
    baseData: {
      alertBannerText: 'Additional Insurance Information Needed',
      problemText: 'We need more insurance info.',
    },
    ctaBlock1: {
      headerText: 'Add Your Primary Insurance Now',
      messageText: 'If you have another insurance, please call us and have your insurance information ready.',
    },
    ctaBlock2: {
      headerText: 'Don’t Have Additional Insurance?',
      buttonText: 'Call Medicaid',
      messageText:
        'If you don’t have another insurance plan, call your current Medicaid provider to let them know this is your only active plan. ',
    },
    proceedWithOrderBlock: {
      closeModalBtnText: 'Back',
      toOrderBtnText: 'Proceed To Order',
      openModalBtnText: 'Proceed With My Order',
      messageText: 'We will not be able to ship the supplies ordered today.',
      headerText: 'Are You Sure?',
    },
    addInsuranceModal: FALLBACK_INSURANCE_MODAL,
  },
  INACTIVE_MEDICAID: {
    baseData: {
      alertBannerText: 'Insurance Update Needed',
      problemText:
        'Your medical supply coverage has expired and is no longer active. To continue receiving free incontinence supplies, we need you to provide current active insurance information for our records.',
    },
    ctaBlock1: {
      headerText: 'Update Your Insurance Now',
      messageText:
        'If you have another insurance, please add it or upload a photo of the front and back of your additional insurance card.',
      buttonText: 'Edit My Insurance',
    },
    ctaBlock2: {
      headerText: 'Nothing Has Changed with My Insurance Plan',
      buttonText: 'Call Medicaid',
      messageText: 'Please call your insurance provider to reinstate your insurance coverage. ',
      visitText: 'Or visit',
    },
    additionalInfo: {
      messageText:
        'Once your policy is reactivated, please contact Aeroflow Urology at 844-276-5588 or info@aeroflowurology.com to provide your updated insurance information. ',
    },
    proceedWithOrderBlock: {
      closeModalBtnText: 'Back',
      toOrderBtnText: 'Proceed To Order',
      openModalBtnText: 'Proceed With My Order',
      messageText: 'We will not be able to ship the supplies ordered today.',
      headerText: 'Are You Sure?',
    },
    editInsuranceModal: FALLBACK_INSURANCE_MODAL_EDIT,
  },
  FAILED_RTE: {
    baseData: {
      alertBannerText: 'Your Insurance Has Changed',
      problemText:
        'There’s an issue with your Medicaid insurance. We may not be able to provide your supplies until the issue is resolved.',
    },
    ctaBlock1: {
      headerText: 'Update Your Insurance Now',
      messageText: 'Please provide new insurance information.',
      buttonText: 'Edit My Insurance',
    },
    ctaBlock2: {
      headerText: 'Don’t Have Updated Insurance?',
      buttonText: 'Call Medicaid',
      messageText: 'Please call your insurance provider if you are unaware of any changes to your insurance.',
      visitText: 'Or visit',
    },
    proceedWithOrderBlock: {
      closeModalBtnText: 'Back',
      toOrderBtnText: 'Proceed To Order',
      openModalBtnText: 'Proceed With My Order',
      messageText: 'We will not be able to ship the supplies ordered today.',
      headerText: 'Are You Sure?',
    },
    editInsuranceModal: FALLBACK_INSURANCE_MODAL_EDIT,
  },
  PRIMARY_INSURANCE_INACTIVE: {
    baseData: {
      alertBannerText: 'Insurance Update Needed',
      problemText:
        'Your Medicaid office needs to remove your primary insurance as it is no longer active. In order to bill for your supplies correctly, please call medicaid and let them know it is your only active plan.',
    },
    ctaBlock: {
      headerText: 'Call Your Insurance Now',
      buttonText: 'Call Medicaid',
    },
    proceedWithOrderBlock: {
      closeModalBtnText: 'Back',
      toOrderBtnText: 'Proceed To Order',
      openModalBtnText: 'Proceed With My Order',
      messageText: 'We will not be able to ship the supplies ordered today.',
      headerText: 'Are You Sure?',
    },
  },
  UNDER_MIN_AGE: {
    baseData: {
      alertBannerText: 'We Cannot Provide Your Supplies Yet',
      header: 'Call Medicaid To Discuss Coverage',
      button: 'Call Medicaid',
      urlVisitText: 'Or visit',
      problem:
        'Your medicaid provider requires children to be {{minAge}} years of age or older to qualify for continence care products. We will reach back out to you when your child is eligible for supplies, but feel free to contact us again after your child has turned {{minAge}}. If you have questions, we recommend contacting your insurance provider directly for more information on coverage requirements.',
    },
    proceedWithOrderBlock: {
      closeModalBtnText: 'Back',
      toOrderBtnText: 'Proceed To Order',
      openModalBtnText: 'Proceed With My Order',
      messageText: 'We will not be able to ship the supplies ordered today.',
      headerText: 'Are You Sure?',
    },
  },
} as const;
